import { browser, dev } from '$app/environment';
import { env } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

export let handleError: HandleClientError = ({ error }) => {
	// eslint-disable-next-line no-console
	console.error(error);
};

if (!dev && browser) {
	Sentry.init({
		dsn: env.PUBLIC_SENTRY_DSN as string,
		environment: env.PUBLIC_SENTRY_ENVIRONMENT as string,
		denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
		ignoreErrors: [
			'Object Not Found Matching Id',
			'Blocked a frame with origin',
			'Failed to fetch',
			'Load failed'
		],
		tracesSampleRate: 0.5,
		integrations: [
			new Sentry.Replay({
				block: ['iframe'],
				maskAllText: false,
				blockAllMedia: true
			})
		],
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0
	});
	handleError = Sentry.handleErrorWithSentry(handleError);
}
